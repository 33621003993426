import { ElementNotFound } from "@senciamatch/frontend/components/ElementNotFound";
import { LoadingData } from "@senciamatch/frontend/components/LoadingData";
import { groupBy } from "@senciamatch/frontend/libs/array";
import { getJapaneseDayOfWeek } from "@senciamatch/frontend/libs/getJapaneseDayOfWeek";
import { trpc } from "@senciamatch/frontend/libs/trpc";
import type { WorkShiftGroup } from "@senciamatch/shared/models/workShiftGroup";
import React from "react";
import { v7 as uuidv7 } from "uuid";
import type { Mode } from "../../index";
import { Cell } from "./Cell";

type Props = {
  workShiftGroup: WorkShiftGroup;
  mode: Omit<Mode, "pickEdit">;
  setSelectedWorkShifts: (ids: Record<string, boolean>) => void;
  selectedWorkShifts: Record<string, boolean>;
};

const GroupByBuilding: React.ForwardRefRenderFunction<HTMLDivElement, Props> = (props, ref) => {
  const { workShiftGroup, mode, setSelectedWorkShifts, selectedWorkShifts } = props;
  const getWorkShifts = trpc.getStaffWorkShiftsByGroupId.useQuery({ workShiftGroupId: workShiftGroup.id });

  if (getWorkShifts.isLoading) return <LoadingData />;
  if (!getWorkShifts.data) return <ElementNotFound />;

  const workShifts = getWorkShifts.data.filter((workShift) => workShift.buildingId != null);
  const workShiftsGroupedByBuildings = groupBy(workShifts, (workShift) => workShift.buildingId as string);

  const lastDayOfMonth = new Date(workShiftGroup.year, workShiftGroup.month, 0).getDate();

  return (
    <div className="h-full overflow-auto ml-8 mt-8" ref={ref}>
      <table className="w-[2450px] border-separate border-spacing-0">
        <thead>
          <tr>
            <th className="sticky top-0 left-0 bg-gray-100 p-2 border border-gray-200 z-40 min-w-[140px]">従業員名</th>
            <th className="sticky top-0 bg-gray-100 p-2 border border-gray-200 z-30 min-w-[100px]">ステータス</th>
            {Array.from({ length: lastDayOfMonth }, (_, i) => {
              const day = i + 1;
              const dayOfWeek = getJapaneseDayOfWeek(workShiftGroup.year, workShiftGroup.month, day);
              const bgColor = dayOfWeek === "日" ? "bg-red-100" : dayOfWeek === "土" ? "bg-blue-100" : "bg-gray-100";
              return (
                <th key={uuidv7()} className={`sticky top-0 ${bgColor} p-2 border border-gray-200 z-10 w-[270px]`}>
                  {day}
                  <br />
                  {dayOfWeek}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {Object.entries(workShiftsGroupedByBuildings).map(([buildingId, workShifts]) => {
            return (
              <tr key={buildingId} className="border border-gray-200 h-20 bg-white">
                <td className="sticky left-0 bg-white border z-20 border-gray-200 p-2 min-w-[140px]">
                  {workShifts[0].buildingName}
                </td>
                <td className="sticky bg-white border z-10 border-gray-200 p-2 min-w-[100px]">
                  <div className="flex items-center justify-center h-full w-[100px]">
                    {workShifts.some((workShift) => workShift.status === "NotConfirmed") ? "未確定" : "未選択"}
                  </div>
                </td>
                {Array.from({ length: lastDayOfMonth }, (_, i) => {
                  const workShift = workShifts.find((workShift) => workShift.day === i + 1);
                  return (
                    <Cell
                      key={uuidv7()}
                      workShiftCell={workShift}
                      mode={mode}
                      setSelectedWorkShifts={setSelectedWorkShifts}
                      selectedWorkShifts={selectedWorkShifts}
                    />
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default React.forwardRef(GroupByBuilding);
