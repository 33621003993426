import { ElementNotFound } from "@senciamatch/frontend/components/ElementNotFound";
import { LoadingData } from "@senciamatch/frontend/components/LoadingData";
import { LoadingSpinner } from "@senciamatch/frontend/components/LoadingSpinner";
import { trpc } from "@senciamatch/frontend/libs/trpc";
import { useMemo, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { EditWorkShiftByBuilding } from "./components/EditWorkShiftByBuilding";
import { EditWorkShiftByStaff } from "./components/EditWorkShiftByStaff";
import { Header } from "./components/Header";
import GroupByBuilding from "./components/groupByBuilding";
import GroupByStaff from "./components/groupByStaff";
export type Mode = "edit" | "pickEdit" | "view";
import { useReactToPrint } from "react-to-print";

export const MatchingShowPage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  if (!id) return <ElementNotFound />;

  const [mode, setMode] = useState<Mode>("view");
  const [displayMode, setDisplayMode] = useState<"staff" | "building">("staff");
  const [selectedWorkShifts, setSelectedWorkShifts] = useState<Record<string, boolean>>({});
  const groupByStaffRef = useRef<HTMLDivElement>(null);
  const groupByBuildingRef = useRef<HTMLDivElement>(null);
  const printGroupByStaffFn = useReactToPrint({ contentRef: groupByStaffRef });
  const printGroupByBuildingFn = useReactToPrint({ contentRef: groupByBuildingRef });

  const getWorkShiftGroup = trpc.getWorkShiftGroup.useQuery({ id });

  const selectedWorkShift = useMemo(() => {
    return Object.entries(selectedWorkShifts)
      .filter(([_, v]) => v)
      .map(([k]) => k);
  }, [selectedWorkShifts]);

  const onPrint = () => {
    if (displayMode === "staff") {
      printGroupByStaffFn();
    } else {
      printGroupByBuildingFn();
    }
  };

  if (getWorkShiftGroup.isLoading) return <LoadingData />;
  if (!getWorkShiftGroup.data) return <ElementNotFound />;

  const workShiftGroup = getWorkShiftGroup.data;

  if (["created", "matching"].includes(workShiftGroup.status))
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="flex flex-col items-center bg-white p-8 rounded-md">
          <LoadingSpinner />
          <p className="text-xl font-bold mt-4">現在シフトの作成中です</p>
        </div>
      </div>
    );

  if (workShiftGroup.status === "cancelled")
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="flex flex-col items-center bg-white p-8 rounded-md">
          <p className="text-xl font-bold mt-4">シフトの作成に失敗しました</p>
        </div>
      </div>
    );

  if (mode === "edit")
    return displayMode === "building" ? (
      <EditWorkShiftByBuilding
        selectedWorkShiftIds={selectedWorkShift}
        clearSelectedWorkShifts={() => setSelectedWorkShifts({})}
        workShiftGroup={workShiftGroup}
        setMode={setMode}
      />
    ) : (
      <EditWorkShiftByStaff
        selectedWorkShiftIds={selectedWorkShift}
        clearSelectedWorkShifts={() => setSelectedWorkShifts({})}
        workShiftGroup={workShiftGroup}
        setMode={setMode}
      />
    );

  return (
    <div className="flex flex-col h-full">
      <h2 className="text-2xl font-bold py-10 flex-shrink-0">{workShiftGroup.name}のシフト一覧</h2>
      <div className="flex-grow overflow-hidden border bg-white border-base-100 flex flex-col">
        <Header
          mode={mode}
          setMode={setMode}
          displayMode={displayMode}
          setDisplayMode={setDisplayMode}
          selectedWorkShift={selectedWorkShift}
          workShiftGroup={workShiftGroup}
          onPrint={onPrint}
        />
        {displayMode === "staff" ? (
          <GroupByStaff
            ref={groupByStaffRef}
            workShiftGroup={workShiftGroup}
            mode={mode}
            setSelectedWorkShifts={setSelectedWorkShifts}
            selectedWorkShifts={selectedWorkShifts}
          />
        ) : (
          <GroupByBuilding
            ref={groupByBuildingRef}
            workShiftGroup={workShiftGroup}
            mode={mode}
            setSelectedWorkShifts={setSelectedWorkShifts}
            selectedWorkShifts={selectedWorkShifts}
          />
        )}
      </div>
    </div>
  );
};
