import { groupBy } from "@senciamatch/frontend/libs/array";
import type { Building } from "@senciamatch/shared/models/building";
import type { BuildingHighPriorityStaff } from "@senciamatch/shared/models/buildingHighPriorityStaff";
import type { Company } from "@senciamatch/shared/models/company";
import type { Staff } from "@senciamatch/shared/models/staff";
import { useState } from "react";
import { v7 as uuidv7 } from "uuid";
import { HighPriorityStaffModal } from "./HighPriorityStaffModal";
interface Props {
  buildingHighPriorityStaffs: BuildingHighPriorityStaff[];
  buildings: Building[];
  mutate: () => void;
  companies: Company[];
  staffs: Staff[];
}

export const BuildingTable: React.FC<Props> = ({
  buildings,
  companies,
  staffs,
  mutate,
  buildingHighPriorityStaffs,
}) => {
  const [showHighPriorityStaffModal, setShowHighPriorityStaffModal] = useState(false);
  const [selectedBuilding, setSelectedBuilding] = useState<Building | null>(null);

  const groupedCompanies = groupBy(companies, (company) => company.id);
  const groupedStaffs = groupBy(staffs, (staff) => staff.id);
  const groupedBuildingHighPriorityStaffs = groupBy(buildingHighPriorityStaffs, (staff) => staff.buildingId);

  return (
    <div className="overflow-y-auto flex-grow border-y border-gray-200">
      <table className="min-w-full">
        <thead className="sticky top-0 bg-gray-100">
          <tr className="bg-gray-100">
            <th className="p-2 text-left border border-gray-200 w-1/12">物件番号</th>
            <th className="p-2 text-left border border-gray-200 w-1/6">物件名</th>
            <th className="p-2 text-left border border-gray-200 w-1/6">住所</th>
            <th className="p-2 text-left border border-gray-200 w-1/6">会社</th>
            <th className="p-2 text-left border border-gray-200 w-1/6">優先スタッフ</th>
            <th className="p-2 text-left border border-gray-200 w-1/6">その他情報</th>
          </tr>
        </thead>
        <tbody className="border-y border-gray-200">
          {buildings.map((building) => {
            const company = groupedCompanies[building.companyId]?.[0];
            return (
              <tr key={uuidv7()}>
                <td className="p-2 text-left border border-gray-200">{building.casNaviId}</td>
                <td className="p-2 text-left border border-gray-200">
                  {building.name}
                  <br />
                  {building.nameKana}
                </td>
                <td className="p-2 text-left border border-gray-200">
                  {building.postalCode}
                  <br />
                  {building.address}
                </td>
                <td className="p-2 text-left border border-gray-200">{company?.name}</td>
                <td className="p-2 text-left border border-gray-200 fl">
                  <p className="mb-2">
                    {groupedBuildingHighPriorityStaffs[building.id]
                      ?.map((hp) => {
                        const staff = groupedStaffs[hp.staffId as keyof typeof groupedStaffs]?.[0];
                        return staff?.name;
                      })
                      .join(" / ")}
                  </p>
                  <button
                    type="button"
                    className="border border-gray-200 rounded-md px-2 py-1 mr-2"
                    onClick={() => {
                      setSelectedBuilding(building);
                      setShowHighPriorityStaffModal(true);
                    }}
                  >
                    設定する
                  </button>
                </td>
                <td className="p-2 text-left border border-gray-200">
                  スーツ着用: {building.isSuitsNeeded ? "必要" : "不要"}
                  <br />
                  清掃: {building.mustCleaning ? "必要" : "不要"}
                  <br />
                  男性専用: {building.menOnly ? "専用" : "共用"}
                  <br />
                  女性専用: {building.womenOnly ? "専用" : "共用"}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      {selectedBuilding && showHighPriorityStaffModal && (
        <HighPriorityStaffModal
          building={selectedBuilding}
          staffs={staffs}
          initialStaffs={groupedBuildingHighPriorityStaffs[selectedBuilding.id]?.map((hp) => hp.staffId) ?? []}
          handleClose={() => {
            setShowHighPriorityStaffModal(false);
            mutate();
          }}
        />
      )}
    </div>
  );
};
