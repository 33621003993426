import { getJapaneseDayOfWeek } from "@senciamatch/frontend/libs/getJapaneseDayOfWeek";
import type { WorkShiftCell } from "@senciamatch/shared/models/workShiftCell";
import { useState } from "react";
import { BuildingShiftTable } from "./table";

interface Props {
  workShift: WorkShiftCell;
  staffShiftGroupId: string;
  show: boolean;
  handleClose: () => void;
  setModifiedWorkShifts: (workShift: WorkShiftCell) => void;
}

export const ModifyModal = (props: Props) => {
  const { show, handleClose, workShift, setModifiedWorkShifts, staffShiftGroupId } = props;

  const [name, setName] = useState<string>();
  const [isExperienced, setIsExperienced] = useState(true);

  if (!show || !workShift) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="w-full h-full relative">
        <div className="bg-white rounded-t-lg shadow-lg w-[90%] h-[90%] absolute bottom-0 left-[5%] flex flex-col">
          <div className="bg-[#EBEBEB] rounded-t-lg flex justify-between items-center p-2 relative h-20 flex-shrink-0">
            <h2 className="text-center text-[24px] font-bold mx-auto">
              {workShift.year}年{workShift.month}月{workShift.day}日（
              {getJapaneseDayOfWeek(workShift.year, workShift.month, workShift.day)}）{workShift.staffName} /{" "}
              {workShift.shiftSymbol}
              の割当物件を探す
            </h2>
            <button
              type="button"
              className="text-gray-500 hover:text-gray-700 absolute font-bold text-[30px] top-[20px] right-[45px]"
              onClick={handleClose}
            >
              &times;
            </button>
          </div>
          <div className="pt-8 px-12 flex-grow flex flex-col overflow-hidden">
            <p className="text-2xl font-bold mb-4">物件名で絞り込む</p>
            <input
              type="text"
              className="p-4 font-bold border border-[#C2C2C2] w-full rounded-md"
              onChange={(e) => setName(e.target.value)}
            />
            <p className="text-2xl font-bold mt-8 mb-4">フィルタリング</p>
            <div className="flex flex-row gap-8">
              <label>
                <input
                  type="checkbox"
                  className="w-4 h-4"
                  checked={isExperienced}
                  onChange={(e) => setIsExperienced(e.target.checked)}
                />
                <span className="ml-2">過去経験がある物件</span>
              </label>
            </div>
            <div className="flex flex-row gap-8 justify-between">
              <p className="h-[75px] leading-[75px] text-[24px] font-bold">物件</p>
            </div>
            <div className="flex-1 overflow-hidden">
              {staffShiftGroupId && workShift.staffShiftId && (
                <BuildingShiftTable
                  name={name}
                  isExperienced={isExperienced}
                  workShift={workShift}
                  setModifiedWorkShifts={setModifiedWorkShifts}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
