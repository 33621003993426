import { FullScreenLoading } from "@senciamatch/frontend/components/FullScreenLoading";
import { groupBy } from "@senciamatch/frontend/libs/array";
import { excelParserBuildingShiftTable } from "@senciamatch/frontend/libs/excelParser/buildingShiftTable";
import { getJapaneseDayOfWeek } from "@senciamatch/frontend/libs/getJapaneseDayOfWeek";
import { trpc } from "@senciamatch/frontend/libs/trpc";
import type { BuildingShiftTable } from "@senciamatch/shared/models/buildingShiftTable";
import type React from "react";
import { type DragEvent, useCallback, useState } from "react";
import { v7 as uuidv7 } from "uuid";

interface Props {
  setCurrentPage: (page: "buildings" | "staffs") => void;
  setBuildingShiftGroupId: (id: string) => void;
}

export const MatchingBuildings: React.FC<Props> = ({ setCurrentPage, setBuildingShiftGroupId }) => {
  const getBuildings = trpc.getBuildings.useQuery();

  const buildings = getBuildings.data?.buildings ?? [];
  const buildingGroupByName = groupBy(buildings, (b) => b.name);

  const [buildingShiftTable, setBuildingShiftTable] = useState<BuildingShiftTable>();
  const createBuildingShiftGroupMutation = trpc.createBuildingShiftGroup.useMutation();

  const handleDragOver = useCallback((e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  }, []);

  const handleDrop = useCallback(
    async (e: React.DragEvent<HTMLDivElement>) => {
      e.preventDefault();
      const file = e.dataTransfer.files[0];
      if (!file) return;
      const reader = new FileReader();

      reader.onload = (e) => {
        const data = e.target?.result;
        if (data instanceof ArrayBuffer) {
          const uint8Array = new Uint8Array(data);
          const parsedData = excelParserBuildingShiftTable.parser(uint8Array, buildingGroupByName);

          console.log(parsedData);

          setBuildingShiftTable(parsedData);
        }
      };
      reader.readAsArrayBuffer(file);
    },
    [buildingGroupByName],
  );

  const handleSubmit = useCallback(async () => {
    if (!buildingShiftTable) return;

    const params = {
      buildingShiftGroup: buildingShiftTable.buildingShiftGroup,
      buildingShifts: Object.values(buildingShiftTable.rows)
        .flatMap((row) => row.cells)
        .filter((cell) => cell != null),
    };

    await createBuildingShiftGroupMutation.mutateAsync(params);

    alert("登録が完了しました");

    setBuildingShiftGroupId(buildingShiftTable.buildingShiftGroup.id);
    setCurrentPage("staffs");
  }, [buildingShiftTable, createBuildingShiftGroupMutation, setBuildingShiftGroupId, setCurrentPage]);

  if (getBuildings.isLoading) return <FullScreenLoading />;

  return (
    <div className="h-screen flex flex-col">
      <h2 className="text-2xl font-bold py-10 flex-shrink-0">物件からのシフト希望登録</h2>
      <div
        onDrop={handleDrop}
        onDragOver={handleDragOver}
        className="rounded-md border-dashed border-2 border-gray-200 bg-white p-4 min-h-40 flex flex-col justify-center items-center mb-4"
      >
        <p className="text-center">ファイルをここにドラッグ＆ドロップしてください</p>
      </div>

      {buildingShiftTable?.errors && (
        <div className="flex flex-col bg-red-50 p-4 mb-4">
          <p className="text-sm font-bold">{buildingShiftTable.errors.length}件のエラーがあります。</p>
          {buildingShiftTable.errors.map((error) => (
            <p key={uuidv7()} className="text-sm">
              {error}
            </p>
          ))}
        </div>
      )}

      {buildingShiftTable?.rows && (
        <div className="flex flex-col h-full">
          <div className="flex-grow overflow-hidden border bg-white border-base-100  flex flex-col">
            <div className="flex items-center justify-between">
              <p className="text-xl font-bold ml-8 mt-8">物件情報</p>
              <button
                type="button"
                className="bg-primary-700 text-white px-4 py-2 rounded-md mt-8 mx-8"
                onClick={handleSubmit}
              >
                スタッフ登録へ
              </button>
            </div>
            <div className="h-full overflow-auto ml-8 mt-8">
              <table className="w-[2450px] border-separate border-spacing-0">
                <thead>
                  <tr>
                    <th className="sticky top-0 left-0 bg-gray-100 p-2 border border-gray-100 z-20 min-w-[300px]">
                      物件
                    </th>
                    {Array.from(
                      { length: buildingShiftTable.rows[Object.keys(buildingShiftTable.rows)[0]].cells.length },
                      (_, i) => {
                        const dayOfWeek = getJapaneseDayOfWeek(
                          buildingShiftTable.buildingShiftGroup.year,
                          buildingShiftTable.buildingShiftGroup.month,
                          i + 1,
                        );
                        const bgColor =
                          dayOfWeek === "日" ? "bg-red-100" : dayOfWeek === "土" ? "bg-blue-100" : "bg-gray-100";
                        return (
                          <th key={uuidv7()} className={`sticky top-0 ${bgColor} p-2 border border-gray-100 z-10`}>
                            {i + 1}
                            <br />
                            {dayOfWeek}
                          </th>
                        );
                      },
                    )}
                  </tr>
                </thead>
                <tbody>
                  {Object.entries(buildingShiftTable.rows).map(([buildingId, row]) => (
                    <tr key={buildingId}>
                      <td className="sticky left-0 p-2 bg-white border border-gray-100 z-10">{row.label}</td>
                      {row.cells.map((s) => {
                        return (
                          <td key={uuidv7()} className="border border-gray-100 w-[270px] h-[30px] bg-white text-center">
                            {s != null ? (
                              <span>
                                {String(s.startHour).padStart(2, "0")}:{String(s.startMinute).padStart(2, "0")} -{" "}
                                {String(s.endHour).padStart(2, "0")}:{String(s.endMinute).padStart(2, "0")}
                              </span>
                            ) : null}
                          </td>
                        );
                      })}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
