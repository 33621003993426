import { ElementNotFound } from "@senciamatch/frontend/components/ElementNotFound";
import { LoadingData } from "@senciamatch/frontend/components/LoadingData";
import { getJapaneseDayOfWeek } from "@senciamatch/frontend/libs/getJapaneseDayOfWeek";
import { trpc } from "@senciamatch/frontend/libs/trpc";
import type { WorkShiftCell } from "@senciamatch/shared/models/workShiftCell";
import type { WorkShiftGroup } from "@senciamatch/shared/models/workShiftGroup";
import { useMemo } from "react";
import { useState } from "react";
import type { Mode } from "../../index";
import { ModifyModal } from "./ModifyModal";

interface Props {
  setMode: (mode: Mode) => void;
  clearSelectedWorkShifts: () => void;
  selectedWorkShiftIds: string[];
  workShiftGroup: WorkShiftGroup;
}

export const EditWorkShiftByStaff = (props: Props) => {
  const { selectedWorkShiftIds, workShiftGroup, setMode, clearSelectedWorkShifts } = props;
  const [modifiedWorkShifts, setModifiedWorkShifts] = useState<Record<string, WorkShiftCell>>({});
  const [showModifyModal, setShowModifyModal] = useState(false);

  const getWorkShifts = trpc.getStaffWorkShiftsByGroupId.useQuery({ workShiftGroupId: workShiftGroup.id });
  const updateWorkShifts = trpc.updateWorkShifts.useMutation();

  const workShifts = getWorkShifts.data;
  const selectedWorkShifts = useMemo(() => {
    return workShifts?.filter((workShift) => selectedWorkShiftIds.includes(workShift.workShiftId));
  }, [workShifts, selectedWorkShiftIds]);

  const workShift = useMemo(() => {
    return workShifts?.find((workShift) => workShift.workShiftId === selectedWorkShiftIds[0]);
  }, [workShifts, selectedWorkShiftIds]);

  const handleBack = () => {
    if (Object.keys(modifiedWorkShifts).length > 0) {
      if (confirm("修正したシフトがあります。戻ると修正したシフトが破棄されます。よろしいですか？")) {
        setMode("pickEdit");
      }
    } else {
      setMode("pickEdit");
    }
  };

  const handleSubmit = async () => {
    const params = [];
    for (const workShiftId of Object.keys(modifiedWorkShifts)) {
      const workShiftCell = modifiedWorkShifts[workShiftId];
      if (!workShiftCell.staffShiftId || !workShiftCell.buildingShiftId) continue;

      params.push({
        id: workShiftCell.workShiftId,
        workShiftGroupId: workShiftGroup.id,
        staffShiftId: workShiftCell.staffShiftId,
        buildingShiftId: workShiftCell.buildingShiftId,
      });
    }

    await updateWorkShifts.mutateAsync(params);
    clearSelectedWorkShifts();
    setMode("view");
  };

  if (getWorkShifts.isLoading) return <LoadingData />;
  if (!getWorkShifts.data) return <ElementNotFound />;

  return (
    <div className="flex flex-col h-full">
      <div className="flex flex-row justify-between">
        <div className="flex flex-row gap-4">
          <button type="button" onClick={handleBack}>
            <img src="/icons/double-arrow-left.svg" alt=">>" className="w-10 h-10 mt-1 p-2 fill-black inline-block" />
          </button>
          <h2 className="text-2xl font-bold py-10 flex-shrink-0">
            選択項目のシフト修正（{selectedWorkShiftIds.length}件）
          </h2>
        </div>
        <div className="flex flex-row gap-4 items-center">
          <button
            type="button"
            onClick={async () => {
              await handleSubmit();
              setMode("view");
            }}
            className="bg-[#00AF31] text-white rounded-md px-4 font-bold h-10"
          >
            修正を適用
          </button>
        </div>
      </div>
      <div className="flex flex-col gap-4">
        {selectedWorkShifts?.map((workShift) => {
          return (
            <div key={workShift.workShiftId}>
              <div className="bg-[#EBEBEB] rounded-t-md p-4 border border-[#EBEBEB text-2xl">
                <p className="font-bold">
                  {workShift.year}年{workShift.month}月{workShift.day}日（
                  {getJapaneseDayOfWeek(workShift.year, workShift.month, workShift.day)}）{workShift.staffName} /{" "}
                  {workShift.shiftSymbol}
                </p>
              </div>
              <div className="bg-white rounded-b-md py-4 px-8 border border-[#EBEBEB]">
                <p className="font-bold text-2xl">現状の登録状況</p>
                <div className="flex flex-row gap-2 my-8 border border-[#EBEBEB]">
                  <p className="bg-[#EBEBEB] p-4 w-40">物件名</p>
                  <p className="p-4">{workShift.buildingName ?? "未割当"}</p>
                </div>

                <p className="font-bold text-2xl mb-4">修正後のシフト</p>

                <button
                  type="button"
                  className="bg-[#00AF31] text-white py-4 rounded-md w-60 font-bold"
                  onClick={() => {
                    setShowModifyModal(true);
                  }}
                >
                  物件を検索
                </button>

                {modifiedWorkShifts[workShift.workShiftId] && (
                  <div className="flex flex-row gap-2 my-8 border border-[#EBEBEB]">
                    <p className="bg-[#EBEBEB] p-4 w-40">物件名</p>
                    <p className="p-4">{modifiedWorkShifts[workShift.workShiftId].buildingName ?? "未割当"}</p>
                  </div>
                )}
              </div>
            </div>
          );
        })}
      </div>
      {workShift && (
        <ModifyModal
          show={showModifyModal}
          handleClose={() => setShowModifyModal(false)}
          workShift={workShift}
          staffShiftGroupId={workShiftGroup.staffShiftGroupId}
          setModifiedWorkShifts={(workShift) => {
            setModifiedWorkShifts((prev) => ({ ...prev, [workShift.workShiftId]: workShift }));
            setShowModifyModal(false);
          }}
        />
      )}
    </div>
  );
};
