import { formatBuildingShift } from "@senciamatch/frontend/libs/getJapaneseDayOfWeek";
import { SHIFT_SYMBOLS, type ShiftSymbol } from "@senciamatch/shared/models/staffShift";
import type { WorkShiftCell } from "@senciamatch/shared/models/workShiftCell";
import { useState } from "react";
import { StaffShiftTable } from "./table";

interface Props {
  workShift: WorkShiftCell;
  staffShiftGroupId: string;
  show: boolean;
  handleClose: () => void;
  setModifiedWorkShifts: (workShift: WorkShiftCell) => void;
}

export const ModifyModal = (props: Props) => {
  const { show, handleClose, workShift, setModifiedWorkShifts, staffShiftGroupId } = props;

  const [name, setName] = useState<string>();
  const [isExperienced, setIsExperienced] = useState(true);
  const [staffShiftSymbols, setStaffShiftSymbols] = useState<ShiftSymbol[]>(SHIFT_SYMBOLS);

  if (!show || !workShift || workShift.buildingShiftId == null) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="w-full h-full relative">
        <div className="bg-white rounded-t-lg shadow-lg w-[90%] h-[90%] absolute bottom-0 left-[5%] flex flex-col">
          <div className="bg-[#EBEBEB] rounded-t-lg flex justify-between items-center p-2 relative h-20 flex-shrink-0">
            <h2 className="text-center text-[24px] font-bold mx-auto">
              {formatBuildingShift({
                year: workShift.year,
                month: workShift.month,
                day: workShift.day,
                buildingName: workShift.buildingName ?? "",
                startHour: workShift.startHour ?? 0,
                startMinute: workShift.startMinute ?? 0,
                endHour: workShift.endHour ?? 0,
                endMinute: workShift.endMinute ?? 0,
              })}
              のシフト変更者を探す
            </h2>
            <button
              type="button"
              className="text-gray-500 hover:text-gray-700 absolute font-bold text-[30px] top-[20px] right-[45px]"
              onClick={handleClose}
            >
              &times;
            </button>
          </div>
          <div className="pt-8 px-12 flex-grow flex flex-col overflow-hidden">
            <p className="text-2xl font-bold mb-4">従業員名で絞り込む</p>
            <input
              type="text"
              className="p-4 font-bold border border-[#C2C2C2] w-full rounded-md"
              onChange={(e) => setName(e.target.value)}
            />
            <p className="text-2xl font-bold mt-8 mb-4">フィルタリング</p>
            <div className="flex flex-row gap-8">
              <label>
                <input
                  type="checkbox"
                  className="w-4 h-4"
                  checked={isExperienced}
                  onChange={(e) => setIsExperienced(e.target.checked)}
                />
                <span className="ml-2">過去経験がある従業員</span>
              </label>
              <div className="flex flex-row gap-8">
                {SHIFT_SYMBOLS.map((symbol) => (
                  <label key={symbol}>
                    <input
                      type="checkbox"
                      className="w-4 h-4"
                      checked={staffShiftSymbols.includes(symbol)}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setStaffShiftSymbols([...staffShiftSymbols, symbol]);
                        } else {
                          setStaffShiftSymbols(staffShiftSymbols.filter((s) => s !== symbol));
                        }
                      }}
                    />
                    <span className="ml-2">{symbol}</span>
                  </label>
                ))}
              </div>
            </div>
            <div className="flex flex-row gap-8 justify-between">
              <p className="h-[75px] leading-[75px] text-[24px] font-bold">従業員</p>
              <p className="text-sm mr-8 mt-8">
                全日：Ａ、午前：Ｂ、深夜：Ｅ、未定：Ｇ、予約なし：―、勤務不可：×、全日（深夜可）：ＡＥ
              </p>
            </div>
            <div className="flex-1 overflow-hidden">
              {staffShiftGroupId && workShift.buildingShiftId && (
                <StaffShiftTable
                  name={name}
                  isExperienced={isExperienced}
                  workShift={workShift}
                  staffShiftSymbols={staffShiftSymbols}
                  setModifiedWorkShifts={setModifiedWorkShifts}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
