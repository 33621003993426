import { LoadingData } from "@senciamatch/frontend/components/LoadingData";
import { trpc } from "@senciamatch/frontend/libs/trpc";
import type { Building } from "@senciamatch/shared/models/building";
import type { Staff } from "@senciamatch/shared/models/staff";
import { useState } from "react";
import { v7 as uuidv7 } from "uuid";

interface Props {
  building: Building;
  staffs: Staff[];
  handleClose: () => void;
  initialStaffs: string[];
}

export const HighPriorityStaffModal: React.FC<Props> = ({ staffs, building, handleClose, initialStaffs }) => {
  const [loading, setLoading] = useState(false);
  const [selectedStaffs, setSelectedStaffs] = useState<Staff[]>(
    initialStaffs
      .map((id) => staffs.find((staff) => staff.id === id))
      .filter((staff): staff is Staff => Boolean(staff)),
  );
  const setHighPriorityStaffMutation = trpc.createBuildingHighPriorityStaffs.useMutation();

  const onSubmit = async () => {
    if (selectedStaffs.length === 0) return;

    setLoading(true);
    await setHighPriorityStaffMutation.mutateAsync({
      buildingId: building.id,
      staffIds: selectedStaffs.map((staff) => staff.id),
    });
    handleClose();
    setLoading(false);
  };

  return (
    <div
      className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50"
      onClick={handleClose}
      onKeyDown={handleClose}
    >
      <div className="w-full h-full relative">
        <div
          className="bg-white rounded-t-lg shadow-lg w-[90%] h-[90%] absolute bottom-0 left-[5%] flex flex-col"
          onClick={(e) => e.stopPropagation()}
          onKeyDown={(e) => e.stopPropagation()}
        >
          <div className="bg-[#EBEBEB] rounded-t-lg flex justify-between items-center p-2 relative h-20 flex-shrink-0">
            <h2 className="text-center text-[24px] font-bold mx-auto">優先スタッフを設定する</h2>
            <button
              type="button"
              className="text-gray-500 hover:text-gray-700 absolute font-bold text-[30px] top-[20px] right-[45px]"
              onClick={handleClose}
            >
              &times;
            </button>
          </div>
          <div className="my-10">
            <h2 className="text-center text-[24px] font-bold mx-auto mb-4">選択済みのスタッフ</h2>
            {selectedStaffs.length > 0 && (
              <div>
                <table className="min-w-full">
                  <thead className="sticky top-0 bg-gray-100">
                    <tr className="bg-gray-100">
                      <th className="p-2 text-left border border-gray-200">スタッフ番号</th>
                      <th className="p-2 text-left border border-gray-200">氏名（漢字）</th>
                      <th className="p-2 text-left border border-gray-200">氏名（カナ）</th>
                      <th className="p-2 text-left border border-gray-200">郵便番号</th>
                      <th className="p-2 text-left border border-gray-200">住所</th>
                      <th className="p-2 text-left border border-gray-200">-</th>
                    </tr>
                  </thead>
                  <tbody>
                    {selectedStaffs.map((staff) => (
                      <tr key={uuidv7()}>
                        <td className="p-2 text-left border border-gray-200">{staff.id}</td>
                        <td className="p-2 text-left border border-gray-200">{staff.name}</td>
                        <td className="p-2 text-left border border-gray-200">{staff.nameKana}</td>
                        <td className="p-2 text-left border border-gray-200">{staff.postalCode}</td>
                        <td className="p-2 text-left border border-gray-200">{staff.address}</td>
                        <td className="p-2 text-left border border-gray-200">
                          <button
                            type="button"
                            className="text-[#00AF31]"
                            onClick={() => setSelectedStaffs(selectedStaffs.filter((s) => s.id !== staff.id))}
                          >
                            解除
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <div className="flex justify-center mt-4">
                  <button type="button" className="bg-[#00AF31] text-white px-4 py-2 rounded-md" onClick={onSubmit}>
                    優先スタッフを設定する
                  </button>
                </div>
              </div>
            )}
          </div>
          {loading ? (
            <LoadingData />
          ) : (
            <div className="overflow-auto flex-1">
              <table className="min-w-full">
                <thead className="sticky top-0 bg-gray-100">
                  <tr className="bg-gray-100">
                    <th className="p-2 text-left border border-gray-200">スタッフ番号</th>
                    <th className="p-2 text-left border border-gray-200">氏名（漢字）</th>
                    <th className="p-2 text-left border border-gray-200">氏名（カナ）</th>
                    <th className="p-2 text-left border border-gray-200">郵便番号</th>
                    <th className="p-2 text-left border border-gray-200">住所</th>
                    <th className="p-2 text-left border border-gray-200">-</th>
                  </tr>
                </thead>
                <tbody>
                  {staffs.map((staff) => (
                    <tr key={uuidv7()}>
                      <td className="p-2 text-left border border-gray-200">{staff.id}</td>
                      <td className="p-2 text-left border border-gray-200">{staff.name}</td>
                      <td className="p-2 text-left border border-gray-200">{staff.nameKana}</td>
                      <td className="p-2 text-left border border-gray-200">{staff.postalCode}</td>
                      <td className="p-2 text-left border border-gray-200">{staff.address}</td>
                      <td className="p-2 text-left border border-gray-200">
                        <button
                          type="button"
                          className="text-[#00AF31]"
                          onClick={() => setSelectedStaffs([...selectedStaffs, staff])}
                        >
                          設定する
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
