import { ElementNotFound } from "@senciamatch/frontend/components/ElementNotFound";
import { LoadingData } from "@senciamatch/frontend/components/LoadingData";
import { formatBuildingShift } from "@senciamatch/frontend/libs/getJapaneseDayOfWeek";
import { trpc } from "@senciamatch/frontend/libs/trpc";
import type { SearchBuildingShift } from "@senciamatch/shared/models/searchBuildingShift";
import type { WorkShiftCell } from "@senciamatch/shared/models/workShiftCell";
import { newWorkShiftCell } from "@senciamatch/shared/models/workShiftCell";

interface Props {
  workShift: WorkShiftCell;
  isExperienced: boolean;
  name: string | undefined;
  setModifiedWorkShifts: (workShift: WorkShiftCell) => void;
}

export const BuildingShiftTable = (props: Props) => {
  const { workShift, isExperienced, name, setModifiedWorkShifts } = props;
  if (!workShift.staffShiftId) return null;

  const getCandidates = trpc.candidateByStaffShift.useQuery({
    staffShiftId: workShift.staffShiftId,
    isExperienced,
    name: name === "" ? undefined : name,
  });

  if (getCandidates.isLoading) return <LoadingData />;
  if (!getCandidates.data) return <ElementNotFound />;

  const candidates = getCandidates.data;
  console.log(candidates);

  const handleAssign = (candidate: SearchBuildingShift) => {
    const newWorkShift = newWorkShiftCell({
      workShiftId: workShift.workShiftId,
      workShiftGroupId: workShift.workShiftGroupId,
      staffShiftId: workShift.staffShiftId,
      staffId: workShift.staffId,
      staffName: workShift.staffName,
      buildingShiftId: candidate.id,
      buildingId: candidate.buildingId,
      buildingName: candidate.buildingName,
      year: candidate.year,
      month: candidate.month,
      day: candidate.day,
      startHour: candidate.startHour,
      startMinute: candidate.startMinute,
      endHour: candidate.endHour,
      endMinute: candidate.endMinute,
      status: "NotConfirmed",
      isFirstShift: false,
    });

    setModifiedWorkShifts(newWorkShift);
  };

  return (
    <div className="h-full overflow-y-auto">
      <table className="min-w-full border-separate border-spacing-0">
        <thead>
          <tr>
            <th className="sticky top-0 bg-gray-100 p-2 border border-gray-200 min-w-[140px]">番号</th>
            <th className="sticky top-0 bg-gray-100 p-2 border border-gray-200 min-w-[100px]">物件情報</th>
            <th className="sticky top-0 bg-gray-100 p-2 border border-gray-200 min-w-[100px]">現在の割当</th>
            <th className="sticky top-0 bg-gray-100 p-2 border border-gray-200 min-w-[100px]">-</th>
          </tr>
        </thead>
        <tbody>
          {candidates.map((candidate) => (
            <tr key={candidate.buildingId}>
              <td className="p-2 text-left border border-gray-200">{candidate.casNaviId}</td>
              <td className="p-2 text-left border border-gray-200">
                {formatBuildingShift({
                  year: candidate.year,
                  month: candidate.month,
                  day: candidate.day,
                  buildingName: candidate.buildingName,
                  startHour: candidate.startHour ?? 0,
                  startMinute: candidate.startMinute ?? 0,
                  endHour: candidate.endHour ?? 0,
                  endMinute: candidate.endMinute ?? 0,
                })}
              </td>
              <td className="p-2 text-left border border-gray-200">
                {candidate.currentWorkShift && <>{candidate.currentWorkShift.staffName}</>}
              </td>
              <td className="p-2 text-left border border-gray-200">
                <button type="button" className="text-[#00AF31]" onClick={() => handleAssign(candidate)}>
                  この物件を割り当てる
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
