import type { WorkShiftGroup } from "@senciamatch/shared/models/workShiftGroup";
import { useNavigate } from "react-router-dom";
import type { Mode } from "../index";

interface Props {
  mode: Mode;
  setMode: (mode: Mode) => void;
  displayMode: "staff" | "building";
  setDisplayMode: (displayMode: "staff" | "building") => void;
  selectedWorkShift: string[];
  workShiftGroup: WorkShiftGroup;
  onPrint: () => void;
}

export const Header = (props: Props) => {
  const { mode, setMode, displayMode, setDisplayMode, selectedWorkShift, workShiftGroup, onPrint } = props;
  const navigate = useNavigate();

  return (
    <>
      <div className="bg-gray-100 h-20 flex items-center gap-4 px-8">
        {mode === "pickEdit" ? (
          <>
            <button
              type="button"
              className={`bg-[#00AF31] text-white px-4 py-2 rounded-md w-40 ${
                selectedWorkShift.length === 0 ? "opacity-50" : ""
              }`}
              onClick={() => {
                if (selectedWorkShift.length === 0) return;
                setMode("edit");
              }}
            >
              選択項目を修正
            </button>
            <button
              type="button"
              className="bg-black text-white px-4 py-2 rounded-md w-40"
              onClick={() => {
                setMode("view");
              }}
            >
              手動修正終了
            </button>
          </>
        ) : (
          <>
            <label className="text-sm flex items-center gap-2">
              <input
                type="radio"
                className="hidden"
                checked={displayMode === "staff"}
                onChange={() => {
                  setDisplayMode("staff");
                }}
              />
              {displayMode === "staff" ? (
                <img src="/icons/radio_button_checked.svg" alt="checked" />
              ) : (
                <img src="/icons/radio_button_unchecked.svg" alt="unchecked" />
              )}
              従業員別
            </label>
            <label className="text-sm flex items-center gap-2">
              <input
                type="radio"
                className="hidden"
                checked={displayMode === "building"}
                onChange={() => {
                  setDisplayMode("building");
                }}
              />
              {displayMode === "building" ? (
                <img src="/icons/radio_button_checked.svg" alt="checked" />
              ) : (
                <img src="/icons/radio_button_unchecked.svg" alt="unchecked" />
              )}
              建物別
            </label>
            <button
              type="button"
              className="bg-black text-white px-4 py-2 rounded-md w-40"
              onClick={() => navigate("/matchings/new")}
            >
              再マッチング
            </button>
            <button
              type="button"
              className="bg-black text-white px-4 py-2 rounded-md w-40"
              onClick={() => {
                setMode("pickEdit");
              }}
            >
              手動修正
            </button>
            <button type="button" className="bg-black text-white px-4 py-2 rounded-md w-40" onClick={() => onPrint()}>
              印刷
            </button>
          </>
        )}
      </div>
      <div className="flex items-center justify-between">
        <p className="text-xl font-bold ml-8 mt-8">{workShiftGroup.name}</p>
        <p className="text-sm mr-8 mt-8">
          全日：Ａ、午前：Ｂ、深夜：Ｅ、未定：Ｇ、予約なし：―、勤務不可：×、全日（深夜可）：ＡＥ
        </p>
      </div>
    </>
  );
};
