import { FullScreenLoading } from "@senciamatch/frontend/components/FullScreenLoading";
import { fileParser } from "@senciamatch/frontend/libs/csv";
import { type BuildingStatus, buildingParser } from "@senciamatch/frontend/libs/csvParser/buildings";
import { trpc } from "@senciamatch/frontend/libs/trpc";
import type { Building } from "@senciamatch/shared/models/building";
import type { Company } from "@senciamatch/shared/models/company";
import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { NewBuildingTable } from "./newBuildingTable";

export const NewBuildingPage: React.FC = () => {
  const [buildings, setBuildings] = useState<Building[]>([]);
  const [statuses, setStatuses] = useState<BuildingStatus>({});
  const [companies, setCompanies] = useState<Company[]>([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showAll, setShowAll] = useState(false);
  const createBuildingsMutation = trpc.createBuildings.useMutation();
  const navigate = useNavigate();

  const utils = trpc.useContext();
  const getBuildings = trpc.getBuildings.useQuery();
  const existingBuildings = getBuildings.data?.buildings ?? [];

  const handleDrop = useCallback(
    async (e: React.DragEvent<HTMLDivElement>) => {
      e.preventDefault();
      const file = e.dataTransfer.files[0];
      if (!file) return;
      const data = await fileParser(file);
      if (!Array.isArray(data)) return;

      await utils.getBuildings.invalidate();

      const parsedData = await buildingParser.parse(data, existingBuildings);

      setBuildings(parsedData.buildings);
      setStatuses(parsedData.status);
      setCompanies(parsedData.companies);
    },
    [existingBuildings, utils.getBuildings.invalidate],
  );

  const handleDragOver = useCallback((e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  }, []);

  const handleSubmit = useCallback(async () => {
    setIsSubmitting(true);
    const params = {
      buildings: buildings.filter((building) => statuses[building.id] !== "persisted"),
      companies: companies,
    };

    const chunkSize = 1000;
    const length = Math.max(params.buildings.length, params.companies.length);
    for (let i = 0; i < length; i += chunkSize) {
      const chunk = {
        buildings: params.buildings.slice(i, i + chunkSize),
        companies: params.companies.slice(i, i + chunkSize),
      };
      await createBuildingsMutation.mutateAsync(chunk);
    }

    setIsSubmitting(false);
    alert("登録が完了しました");
    navigate("/buildings");
  }, [buildings, companies, createBuildingsMutation, statuses, navigate]);

  return (
    <div className="h-screen flex flex-col">
      <h2 className="text-2xl font-bold py-10 flex-shrink-0">物件の登録</h2>
      <div
        onDrop={handleDrop}
        onDragOver={handleDragOver}
        className="rounded-md border-dashed border-2 border-gray-200 bg-white p-4 min-h-40 flex flex-col justify-center items-center mb-10"
      >
        <p className="text-center">ファイルをここにドラッグ＆ドロップしてください</p>
      </div>

      {buildings.length > 0 && (
        <div className="overflow-x-auto py-10 bg-white border border-base-100 px-8 flex-grow flex flex-col">
          <label className="flex items-center gap-2 mb-4">
            <input type="checkbox" className="checkbox" checked={showAll} onChange={() => setShowAll(!showAll)} />
            <span className="label-text">追加・更新分以外も表示</span>
          </label>
          <NewBuildingTable
            buildings={showAll ? buildings : buildings.filter((building) => statuses[building.id] !== "persisted")}
            companies={companies}
          />
          <button type="button" className="bg-primary-700 text-white px-4 py-2 rounded-md mt-4" onClick={handleSubmit}>
            新規登録
          </button>
        </div>
      )}

      {isSubmitting && <FullScreenLoading />}
    </div>
  );
};
