import { ElementNotFound } from "@senciamatch/frontend/components/ElementNotFound";
import { LoadingData } from "@senciamatch/frontend/components/LoadingData";
import { formatBuildingShift } from "@senciamatch/frontend/libs/getJapaneseDayOfWeek";
import { trpc } from "@senciamatch/frontend/libs/trpc";
import type { SearchStaffShift } from "@senciamatch/shared/models/searchStaffShift";
import type { ShiftSymbol } from "@senciamatch/shared/models/staffShift";
import type { WorkShiftCell } from "@senciamatch/shared/models/workShiftCell";
import { newWorkShiftCell } from "@senciamatch/shared/models/workShiftCell";

interface Props {
  workShift: WorkShiftCell;
  isExperienced: boolean;
  name: string | undefined;
  staffShiftSymbols: ShiftSymbol[];
  setModifiedWorkShifts: (workShift: WorkShiftCell) => void;
}

export const StaffShiftTable = (props: Props) => {
  const { workShift, isExperienced, name, setModifiedWorkShifts } = props;
  if (!workShift.buildingShiftId) return null;

  const getCandidates = trpc.candidateByBuildingShift.useQuery({
    buildingShiftId: workShift.buildingShiftId,
    isExperienced,
    name: name === "" ? undefined : name,
  });

  if (getCandidates.isLoading) return <LoadingData />;
  if (!getCandidates.data) return <ElementNotFound />;

  const candidates = getCandidates.data;

  const handleAssign = (candidate: SearchStaffShift) => {
    const newWorkShift = newWorkShiftCell({
      workShiftId: workShift.workShiftId,
      workShiftGroupId: workShift.workShiftGroupId,
      staffShiftId: candidate.id,
      staffId: candidate.staffId,
      staffName: candidate.staffName,
      buildingShiftId: workShift.buildingShiftId,
      buildingId: workShift.buildingId,
      year: workShift.year,
      month: workShift.month,
      day: workShift.day,
      startHour: workShift.startHour,
      startMinute: workShift.startMinute,
      endHour: workShift.endHour,
      endMinute: workShift.endMinute,
      status: "NotConfirmed",
      isFirstShift: false,
    });

    setModifiedWorkShifts(newWorkShift);
  };

  return (
    <div className="h-full overflow-y-auto">
      <table className="min-w-full border-separate border-spacing-0">
        <thead>
          <tr>
            <th className="sticky top-0 bg-gray-100 p-2 border border-gray-200 min-w-[140px]">番号</th>
            <th className="sticky top-0 bg-gray-100 p-2 border border-gray-200 min-w-[100px]">従業員</th>
            <th className="sticky top-0 bg-gray-100 p-2 border border-gray-200 min-w-[100px]">提出シフト</th>
            <th className="sticky top-0 bg-gray-100 p-2 border border-gray-200 min-w-[100px]">現在のシフト</th>
            <th className="sticky top-0 bg-gray-100 p-2 border border-gray-200 min-w-[100px]">-</th>
          </tr>
        </thead>
        <tbody>
          {candidates.map((candidate) => {
            if (!props.staffShiftSymbols.includes(candidate.shiftSymbol)) return null;
            return (
              <tr key={candidate.staffId}>
                <td className="p-2 text-left border border-gray-200">{candidate.staffId}</td>
                <td className="p-2 text-left border border-gray-200">{candidate.staffName}</td>
                <td className="p-2 text-left border border-gray-200">{candidate.shiftSymbol}</td>
                <td className="p-2 text-left border border-gray-200">
                  {candidate.currentWorkShift &&
                    formatBuildingShift({
                      year: candidate.currentWorkShift.year,
                      month: candidate.currentWorkShift.month,
                      day: candidate.currentWorkShift.day,
                      buildingName: candidate.currentWorkShift.buildingName ?? "",
                      startHour: candidate.currentWorkShift.startHour,
                      startMinute: candidate.currentWorkShift.startMinute,
                      endHour: candidate.currentWorkShift.endHour,
                      endMinute: candidate.currentWorkShift.endMinute,
                    })}
                </td>
                <td className="p-2 text-left border border-gray-200">
                  <button type="button" className="text-[#00AF31]" onClick={() => handleAssign(candidate)}>
                    この従業員に割り当てる
                  </button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};
