import { type Building, createBuilding } from "@senciamatch/shared/models/building";
import type { Company } from "@senciamatch/shared/models/company";
import { newCompany } from "@senciamatch/shared/models/company";

// CSV format
const CSV_FORMAT = [
  "ｸﾗｲｱﾝﾄ番号", // 0
  "ｸﾗｲｱﾝﾄ名", // 1
  "現場番号", // 2
  "現場名", // 3
  "現場名(ｶﾅ)",
  "郵便番号", // 5
  "住所1", // 6
  "住所2", // 7
  "住所3", // 8
  "TEL", // 9
  "FAX", // 10
  "部署名", // 11
  "担当者", // 12
  "役職", // 13
  "TEL(担当者)", // 14
  "FAX(担当者)", // 15
  "内線(担当者)", // 16
  "E-Mail(担当者)", // 17
  "集合場所", // 18
  "最寄駅", // 19
  "備考", // 20
  "旧抵触日", // 21
  "派遣先事業所番号", // 22
  "派遣先事業所名", // 23
  "組織単位番号", // 24
  "組織単位名", // 25
  "所在地を登録する", // 26
  "郵便番号", // 27
  "住所１", // 28
  "住所２", // 29
  "住所３", // 30
  "TEL", // 31
  "FAX", // 32
];

export interface BuildingStatus {
  [buildingId: string]: "new" | "edited" | "persisted";
}

const validateCSV = (data: string[][]) => {
  const title = data[0];
  if (title.length !== CSV_FORMAT.length) return false;

  for (let i = 0; i < CSV_FORMAT.length; i++) {
    if (title[i] !== CSV_FORMAT[i]) return false;
  }

  return true;
};

const rowStatus = (rowBuilding: Building, existingBuildings: Building[]): BuildingStatus => {
  const existingBuilding = existingBuildings.find(
    (building) => building.casNaviId === rowBuilding.casNaviId && building.companyId === rowBuilding.companyId,
  );

  if (!existingBuilding) return { [rowBuilding.id]: "new" };

  // if (existingBuilding.name !== rowBuilding.name) return { [rowBuilding.id]: "edited" };
  // if (existingBuilding.postalCode !== rowBuilding.postalCode) return { [rowBuilding.id]: "edited" };
  // if (existingBuilding.address !== rowBuilding.address) return { [rowBuilding.id]: "edited" };
  // if (existingBuilding.isSuitsNeeded !== rowBuilding.isSuitsNeeded) return { [rowBuilding.id]: "edited" };
  // if (existingBuilding.mustCleaning !== rowBuilding.mustCleaning) return { [rowBuilding.id]: "edited" };
  // if (existingBuilding.menOnly !== rowBuilding.menOnly) return { [rowBuilding.id]: "edited" };
  // if (existingBuilding.womenOnly !== rowBuilding.womenOnly) return { [rowBuilding.id]: "edited" };

  return { [rowBuilding.id]: "persisted" };
};

const parse = async (
  data: string[][],
  existingBuildings: Building[],
): Promise<{ buildings: Building[]; status: BuildingStatus; companies: Company[] }> => {
  const status: BuildingStatus = {};
  const companies: { [companyId: string]: Company } = {};

  const buildings = data
    .slice(1)
    .map((row) => {
      const buildingId = row[2];
      if (Number.isNaN(Number(buildingId))) return null;

      const rowBuilding = createBuilding({
        casNaviId: Number(buildingId),
        name: row[3],
        nameKana: row[4],
        postalCode: row[5],
        address: [row[6], row[7], row[8]].join(" ").trim(),
        companyId: row[0],
        isSuitsNeeded: false,
        mustCleaning: false,
        menOnly: false,
        womenOnly: false,
      });

      status[rowBuilding.id] = rowStatus(rowBuilding, existingBuildings)[rowBuilding.id];

      const company = newCompany({
        id: row[0],
        name: row[1],
      });
      companies[company.id] = company;

      return rowBuilding;
    })
    .filter((building) => building != null);

  return { buildings, status, companies: Object.values(companies) };
};

export const buildingParser = {
  validateCSV,
  parse,
};
