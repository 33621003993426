import { FullScreenLoading } from "@senciamatch/frontend/components/FullScreenLoading";
import { groupBy } from "@senciamatch/frontend/libs/array";
import { excelParserImportShift } from "@senciamatch/frontend/libs/excelParser/importShift";
import { trpc } from "@senciamatch/frontend/libs/trpc";
import type { BuildingShift } from "@senciamatch/shared/models/buildingShift";
import type { BuildingShiftGroup } from "@senciamatch/shared/models/buildingShiftGroup";
import type { StaffShift } from "@senciamatch/shared/models/staffShift";
import type { StaffShiftGroup } from "@senciamatch/shared/models/staffShiftGroup";
import type { WorkShift } from "@senciamatch/shared/models/workShift";
import type { WorkShiftGroup } from "@senciamatch/shared/models/workShiftGroup";
import type React from "react";
import { type DragEvent, useCallback, useState } from "react";

export const WorkShiftImport = () => {
  const getBuildings = trpc.getBuildings.useQuery();

  const buildings = getBuildings.data?.buildings ?? [];
  const buildingGroupByName = groupBy(buildings, (b) => b.name);

  const [data, setData] = useState<{
    buildingShiftGroup: BuildingShiftGroup;
    staffShiftGroup: StaffShiftGroup;
    workShiftGroup: WorkShiftGroup;
    buildingShifts: BuildingShift[];
    workShifts: WorkShift[];
    staffShifts: StaffShift[];
  }>();

  const createBuildingShiftGroupMutation = trpc.createBuildingShiftGroup.useMutation();
  const createWorkShiftGroupMutation = trpc.createWorkShiftGroup.useMutation();
  const createStaffShiftGroupMutation = trpc.createStaffShiftGroup.useMutation();
  const createWorkShiftsMutation = trpc.createWorkShifts.useMutation();
  const handleDragOver = useCallback((e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  }, []);

  const handleDrop = useCallback(
    async (e: React.DragEvent<HTMLDivElement>) => {
      e.preventDefault();
      const file = e.dataTransfer.files[0];
      if (!file) return;
      const reader = new FileReader();

      reader.onload = (e) => {
        const data = e.target?.result;
        if (data instanceof ArrayBuffer) {
          const uint8Array = new Uint8Array(data);
          const parsedData = excelParserImportShift.parser(uint8Array, buildingGroupByName);
          console.log("parsedData", parsedData);
          setData(parsedData);
        }
      };
      reader.readAsArrayBuffer(file);
    },
    [buildingGroupByName],
  );

  const createBuildingShiftGroup = useCallback(async () => {
    if (!data) return;

    const params = {
      buildingShiftGroup: data.buildingShiftGroup,
      buildingShifts: data.buildingShifts,
    };
    await createBuildingShiftGroupMutation.mutateAsync(params);
  }, [data, createBuildingShiftGroupMutation]);

  const createStaffShiftGroup = useCallback(async () => {
    if (!data) return;
    const params = {
      staffShiftGroup: data.staffShiftGroup,
      staffShifts: data.staffShifts,
    };
    await createStaffShiftGroupMutation.mutateAsync(params);
  }, [data, createStaffShiftGroupMutation]);

  const createWorkShiftGroup = useCallback(async () => {
    if (!data) return;

    await createWorkShiftGroupMutation.mutateAsync(data.workShiftGroup);
  }, [data, createWorkShiftGroupMutation]);

  const createWorkShifts = useCallback(async () => {
    if (!data) return;

    await createWorkShiftsMutation.mutateAsync({ workShifts: data.workShifts });
  }, [data, createWorkShiftsMutation]);

  const handleSubmit = useCallback(async () => {
    if (!data) return;

    await createBuildingShiftGroup();
    await createStaffShiftGroup();
    await createWorkShiftGroup();
    await createWorkShifts();

    alert("登録が完了しました");
  }, [data, createBuildingShiftGroup, createStaffShiftGroup, createWorkShiftGroup, createWorkShifts]);

  if (getBuildings.isLoading) return <FullScreenLoading />;

  return (
    <div className="h-screen flex flex-col">
      <h2 className="text-2xl font-bold py-10 flex-shrink-0">過去のシフト登録</h2>
      <div
        onDrop={handleDrop}
        onDragOver={handleDragOver}
        className="rounded-md border-dashed border-2 border-gray-200 bg-white p-4 min-h-40 flex flex-col justify-center items-center mb-10"
      >
        <p className="text-center">ファイルをここにドラッグ＆ドロップしてください</p>
      </div>
      <button
        type="button"
        onClick={handleSubmit}
        className="bg-primary-700 text-white p-2 rounded-md w-full max-w-[600px] mx-auto"
      >
        登録
      </button>
    </div>
  );
};
